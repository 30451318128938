<template>
	<router-view></router-view>
<!--	<router-view v-slot="{Component}">
		<transition
				:enter-active-class="`animate__animated animate__${$route.meta.enterTransition}`"
				leave-active-class="animate__animated animate__fadeOut"
				mode="out-in">
			<component v-bind:is="Component" :key="$route.path"></component>
		</transition>
	</router-view>-->
</template>

<script>

export default {
	name: 'App',
	created() {
		this.$store.dispatch("autosignin");
	},
	computed: {
		token() {
			return this.$store.getters.token;
		},
	},
	watch: {
		token: {
			handler() {
				if (this.token) {
					this.$store.dispatch("fetchProducts");
				}
			},
			immediate: true,
		}
	}
};
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~animate.css/animate.min.css";

.bg-vue {
	background-color: rgb(52, 73, 94);
	color: white;
}

.bg-vue-button {
	background-color: rgb(52, 73, 94);
	color: white;
	transition: color 0.7s ease;
}

.bg-vue-button:hover {
	background-color: rgb(72, 93, 114);
	color: black;
}

.bg-vue2 {
	background-color: rgb(65, 184, 131);
	color: white;
}

.bg-vue2-button {
	background-color: rgb(65, 184, 131);
	color: white;
	transition: color 0.7s ease;
}

.bg-vue2-button:hover {
	background-color: rgb(85, 204, 151);
	color: black;
}

.text-vue {
	color: rgb(52, 73, 94);
}

.text-vue2 {
	color: rgb(65, 184, 131);
}

html, body {
	overflow-x: hidden;
}

</style>
