<template>
	<nav class="navbar navbar-expand-lg bg-vue navbar-dark">
		<div class="container">
			<router-link class="navbar-brand" to="/shop">Der Shop</router-link>
			<ul class="navbar-nav me-auto">
				<li class="nav-item active">
					<router-link class="nav-link" to="/shop">Startseite</router-link>
				</li>
				<li class="nav-item">
					<router-link class="nav-link" to="/shop/create/product">Neuer Artikel</router-link>
				</li>
			</ul>
			<button class="btn bg-vue-button me-3">
				<i class="fas fa-shopping-cart"></i> Warenkorb ({{ cartSum }} €)
			</button>
			<button class="btn bg-vue2-button" v-on:click="signout">
				<i class="fas fa-sign-out-alt"></i> Logout
			</button>
		</div>
	</nav>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "TheNavbar",
	/*computed: {
		cartTotal() {
			return this.$store.getters.cartTotal;
		},
	},*/
	computed: {
		//...mapGetters(["cartTotal"]),
		...mapGetters({ cartSum: "cartTotal"}),
	},
	methods: {
		async signout() {
			try {
				await this.$store.dispatch("signout");
				this.$router.push("/");
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>

<style scoped>
</style>
