import {createRouter, createWebHistory} from "vue-router";
import store from "@/store";
import NotFoundPage from "@/pages/NotFoundPage.vue";
import routes from "@/router/routes";

const router = createRouter({
	history: createWebHistory(),
	routes:
			[
				...routes,
				{
					path: "/:pathMatch(.*)*",
					component: NotFoundPage,
				},
			],
});

router.beforeEach((to, from, next) => {
	if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
		next("/");
	} else {
		next();
	}
});

export default router;
